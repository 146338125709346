var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"patient-list"}},[_c('list-template',_vm._b({attrs:{"update-component":_vm.updateComponent},on:{"fetch:data":_vm.fetchData,"delete:confirm":_vm.deleteConfirm},scopedSlots:_vm._u([{key:"create-section",fn:function(){return [_c('a',{staticClass:"mr-3 mt-1 anchor-link",staticStyle:{"font-size":"13px"},attrs:{"href":"javascript:void(0)"},on:{"click":_vm.triggerAddPatient}},[_vm._v(" Add New Patient")])]},proxy:true},{key:"custom-cell-ref",fn:function(ref){
var row = ref.row;
return [_c('span',[_c('router-link',{staticClass:"anchor-link",attrs:{"to":{
            name: 'patientOverview',
            params: {
              tab: 'patientOverview',
              id: row.item.id
            }
          }}},[_vm._v(" "+_vm._s(row.item.ref)+" ")]),_c('br'),(row.item.discontinuationVisitId)?_c('b-badge',[_vm._v(" Discontinued ")]):_vm._e()],1)]}},{key:"actions",fn:function(ref){
          var row = ref.row;
return [_c('dropdown-table-action',{attrs:{"items":_vm.config.tableAction,"row":row}})]}}])},'list-template',_vm.config,false)),_c('modal-change-physician',{attrs:{"config":_vm.config.modal.setPhysician,"role":_vm.userData.role,"hospitalList":_vm.hospitalList,"physiciansList":_vm.physiciansList},on:{"update:selected-physician":_vm.setSelectedPhysician,"action:fetch-physicians":_vm.getPhysicians,"action:submit":_vm.startUserRegistrationFlow,"action:update":_vm.patchPhysician,"action:close":_vm.onChangePhysicianModalClosed}}),_c('modal-change-opinion',{attrs:{"config":_vm.config.modal.secondOpinion,"role":_vm.userData.role,"hospitalList":_vm.hospitalList,"physiciansList":_vm.physiciansList},on:{"action:set-selected-hospital":_vm.setSelectedHospital,"action:fetch-physicians":_vm.getPhysicians,"action:get-selected-physician":_vm.getSelectedPhysician,"action:assign-physician":_vm.assignPhysicianForOpinion,"action:remove-physician":_vm.deletePhysiciansAssigned,"action:close":_vm.opinionModalClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }